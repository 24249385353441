@import '../bootstrap/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@import 'accessible-autocomplete';

.sales-sites-search-form {
    display: block;

    #sales-sites-input {
        border-right-width: 0;
        border-left: 1px solid $gray-light;
    }

    .input-group {
        .btn {
            border: 1px solid $gray-light;
            border-left-width: 0;
            padding: 0.8rem 0.5rem;
        }
    }

    .btn-link {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        padding: 0;
        color: $gray-darker;
        text-align: left;
        text-decoration: none;

        i.icon-place::before {
            margin-left: -0.2em;
        }
    }

    .location-message,
    .search-message {
        &.alert {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }
}

.sales-sites-results {
    max-height: 460px;
    overflow-y: auto;

    ul {
        padding-left: 0;
        list-style: none;

        li {
            margin-bottom: 0.5rem;
            border-bottom: 1px solid $gray-light;
            padding-bottom: 1rem;

            p {
                color: $gray-darker;
            }
        }
    }
}

.ratio-sales-site-map {
    --bs-aspect-ratio: 100%;

    @include media-breakpoint-up(md) {
        --bs-aspect-ratio: 80%;
    }
}
