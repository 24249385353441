@import '~accessible-autocomplete';

.sales-sites-search-form {
    .autocomplete-container {
        flex: 1;

        .autocomplete__wrapper {
            >span {
                display: none; // sometimes a single span is added above the input field???
            }
        }
    }

    .autocomplete__input {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        padding: 0.75rem 0.75rem;
    }

    .autocomplete__hint,
    .autocomplete__input {
        border: 1px solid $gray-light;
    }

    .autocomplete__hint {
        position: absolute;
        color: $gray-dark;
    }

    .autocomplete__input--focused {
        outline: none;
    }

    .autocomplete__menu {
        border: 1px solid $gray-light;
        border-top: 0;
        color: $body-color;
        background-color: #fff;
    }

    .autocomplete__option {
        border-bottom: 1px solid $gray-light;
    }

    .autocomplete__hint,
    .autocomplete__input,
    .autocomplete__option {
        font-size: inherit;
        line-height: 1.6;
    }

    .autocomplete__option--focused,
    .autocomplete__option:hover {
        border-color: $gray-light;
        color: $body-color;
        background-color: #fff;
        outline: none;
    }

    @media (min-width: 641px) {
        .autocomplete__hint,
        .autocomplete__input,
        .autocomplete__option {
            font-size: inherit;
            line-height: 1.6;
        }
    }
}
